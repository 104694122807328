import swiper from "swiper";
import isMobile from "../../plugins/is-mobile.js";

if ($(".js-top-hero").length) {
  var heroMain = new swiper(".js-top-hero", {
    // Optional parameters
    direction: "horizontal",
    loop: false,
    effect: "fade",
    slidesPerView: 1,
    speed: 500,
    observer: true,
    observeParents: true,
    preloadImages: true,
    autoHeight: false,
    allowTouchMove: false,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2
    },
    breakpoints: {
      515: {
        autoHeight: true
      }
    }
  });

  if (!isMobile(768)) {
    $(".top-hero-localNav-item").hover(function() {
      var index = $(this).index();
      var realindex = index + 1;
      heroMain.slideTo(realindex);
    });
  } else {
    $(".top-hero-localNav-item").click(function() {
      var index = $(this).index();
      var realindex = index + 1;
      let that = $(".top-hero-localNav-hover__item").eq(index);
      heroMain.slideTo(realindex);
      $(".top-hero-localNav-hover__item").removeClass("is-active");
      that.addClass("is-active");
    });
  }

  if (!isMobile(768)) {
    document.addEventListener("mouseover", e => {
      if (!e.target.closest(".top-hero-slider")) {
        if (!e.target.closest(".top-hero-localNav-item")) {
          heroMain.slideTo(0);
        }
      }
    });
  }
}
