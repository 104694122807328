$('span.ajax-loader').hide();

$('.c-form-inputItem-radio .wpcf7-list-item').on('click', function(){
	$('.c-form-inputItem-radio .wpcf7-list-item').removeClass('is-active');
		$(this).addClass('is-active');
});

$('.c-form-select').each(function() {
	$(this).find('select').wrap('<div class="c-form-select__inner"></div>');
})

document.addEventListener( 'wpcf7submit', function( event ) {
	switch ( event.detail.status ) {
		case 'wpcf7c_confirmed':
		$('body').addClass('wp-contactform7-confirm-page');
		$("input[type=radio]:not(:checked)").closest('.wpcf7-list-item').hide();
		// $("input[type=checkbox]:not(:checked)").closest('span').hide();
		// $(".txt_comment").hide();
		$("input[name=inquiry_mail_confirm]").closest('.sw-form__emailItem').hide();
		// $("div.c-form").addClass("is-confirm");
		// $('li.c-form__stepItem').eq(0).removeClass('is-current');
		// $('li.c-form__stepItem').eq(1).addClass('is-current');
		// $('li.c-form__stepItem').eq(0).addClass('is-complete');
		break;
	}
}, false );

document.addEventListener( 'wpcf7mailsent', function( event ) {
	if(document.querySelector('.page-contact')) {
  location = '/contact/thanks/'; /* 遷移先のURL */
	} else {
  location = '/entry/thanks/'; /* 遷移先のURL */
	}
}, false );
$('.wpcf7c-btn-back').on('click', function(){
	$('body').removeClass('wp-contactform7-confirm-page');
	$("input[type=radio]:not(:checked)").closest('.wpcf7-list-item').show();
	$("input[name=inquiry_mail_confirm]").closest('.sw-form__emailItem').show();
});

