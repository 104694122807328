var store = require("store");
import { TweenMax } from "gsap/TweenMax";
var tl = new TimelineMax();
var w_height = $(window).height();

//　トップページでアニメーション再生するか判断
var isTopPage = -1 < $.inArray(location.pathname, ["/", "/index.php"]);
var pathname = function() {
  return location.pathname == "/index.php" ? "/" : location.pathname;
};

if (isTopPage) {
  // ==================================================
  // 外部からこのサイトを訪問したユーザーには必ずアニメーションを見せる
  // いちどアニメーションを見せたらサイト内部を回遊している間はアニメーションを見せない
  // ※トップページでリロードした場合は、アニメーションを見せる
  // ==================================================
  var fromOutside = !document.referrer.match(location.host);

  if (fromOutside) {
    store.set("referrer", location.pathname);
  }

  if (pathname() == store.get("referrer")) {
    $("body").data("animate", true);
  }
}
store.set("referrer", pathname());

//トップページに記述する
if ($("body").data("animate")) {
  $(".top-loading").height(w_height);
  tl
    .to($("body"), 0, { className: "+=is-loading" })
    .to($("#loading"), 0.1, { css: { color: "#B93829" } })
    .to($("#loading"), 0.7, { css: { color: "#709C08" } })
    .to($("#loading"), 0.7, { css: { color: "#0495C2" } })
    .to($("#loading"), 0.7, { css: { color: "#BE953F" } })
    .to($("#loading"), 0.7, { css: { color: "#E27558" } })
    .to($(".top-loading"), 0.8, { css: { opacity: "0" } })
    .to($(".top-loading"), 0.8, { css: { visibility: "hidden" } })
    .to($(".top-hero-main__detail"), 0.5, {
      css: { opacity: "1", transform: "translate(-50%,-50%)" }
    })
    .to($("body"), -0.8, { className: "-=is-loading" });
    
  //実行したいものを書く
} else {
  $(".top-loading").hide();
}
